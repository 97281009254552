import React, { useState, useEffect } from "react";
import Approach from "./approach.jsx";

function Approaches(props) {
  const [approaches, setApproaches] = useState(() => {
    let approachesArr = [];
    props.approaches.forEach((approach, i) => {
      approach["isOpen"] = false;
      approach["key"] = i;
      approachesArr.push(approach);
    });

    return approachesArr;
  });

  function openApproach(key) {
    let prevApproaches = approaches;
    let updatedApproaches = [];

    approaches.forEach((approach, i) => {
      if (key == approach.key) {
        approach.isOpen = true;
      } else {
        approach.isOpen = false;
      }

      updatedApproaches.push(approach);
    });

    setApproaches(updatedApproaches);

    let approachesElems = document.querySelectorAll(".approach");
    approachesElems.forEach((approachElem, i) => {
      let approachKey = approachElem.getAttribute("keyattr");
      if (approachKey == key) {
        setTimeout(function() {
          let top = approachElem.getBoundingClientRect().top;
          if (top < 0) {
            approachElem.scrollIntoView({ behavior: "smooth" });
          }
        }, 10);
      }
    });
  }

  return (
    <div className="approaches">
      {approaches.map((approach, i) => {
        return (
          <Approach
            key={i}
            keyProp={i}
            approach={approach}
            openApproach={openApproach}
          />
        );
      })}
    </div>
  );
}

export default Approaches;
