import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Publication from "./publication.jsx";

function MainText(props) {
  useEffect(() => {
    publications();
  });

  const mainTextEl = useRef(null);

  function publications() {
    let stories, products, speeches;
    let links = mainTextEl.current.querySelectorAll("a");
    links.forEach((link, i) => {
      let title = link.getAttribute("title");
      hover(title, link);
      link.setAttribute("title", "");
      link.setAttribute("href", "javascript:;");
    });
  }

  function hover(category, link) {
    link.addEventListener("click", function(e) {
      // e.preventDefault();
    });

    let publications = [];
    props.publications.forEach((publication, i) => {
      if (publication.type.toUpperCase() == category.toUpperCase()) {
        publications.push(publication);
      }
    });

    let publication =
      publications[Math.floor(Math.random() * publications.length)];
    let rect = link.getBoundingClientRect();

    let reactPublication = (
      <Publication publication={publication} rect={rect} />
    );
    let span = document.createElement("span");

    link.addEventListener("mouseenter", function(e) {
      link.appendChild(span);
      ReactDOM.render(reactPublication, span);
    });

    link.addEventListener("mouseleave", function(e) {
      link.removeChild(span);
    });
  }

  return (
    <div
      ref={mainTextEl}
      className="mainText"
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  );
}

export default MainText;
