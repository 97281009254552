import gql from "graphql-tag";
const QUERY = gql`
  query data {
    general(where: { id: "ck8z4bc002n2n0104v4bu6m59" }) {
      approach {
        html
      }
      contacts
      mainText {
        html
      }
      trust
      partners {
        html
      }
      email
      telegram
      gallery {
        image {
          caption
          image {
            url
          }
        }
      }
    }
    publications {
      link
      text
      title
      image {
        url
      }
      type
    }

    projects(orderBy: realDate_DESC) {
      name
      date
      description {
        html
      }
      publication {
        text
        title
        link
        image {
          url
        }
      }
    }

    approaches {
      title
      caption
      description
    }

    partners {
      name
      logo {
        url
      }
      logoWidth
    }
  }
`;
export default QUERY;
