import React, { useState, useEffect } from "react";
import Project from "./project.jsx";

function Projects(props) {
  const [projects, setProjects] = useState(() => {
    let projectsArr = [];
    props.projects.forEach((project, i) => {
      project["isOpen"] = false;
      project["key"] = i;
      projectsArr.push(project);
    });

    return projectsArr;
  });

  const [margin, setMargin] = useState(0);

  useEffect(() => {
    // setTimeout(function() {
    //   setProjectDateWidth();
    // }, 10);
    // setProjectContentMargin();

    setTimeout(function() {
      setProjectDateWidth();
    }, 10);
  });

  function openProject(key) {
    setProjectContentMargin();
    let prevProjects = projects;
    let updatedProjects = [];

    projects.forEach((project, i) => {
      if (key == project.key) {
        project.isOpen = true;
      } else {
        project.isOpen = false;
      }

      updatedProjects.push(project);
    });

    setProjects(updatedProjects);

    let projectsElems = document.querySelectorAll(".project");
    projectsElems.forEach((projectElem, i) => {
      let projectKey = projectElem.getAttribute("keyattr");
      if (projectKey == key) {
        setTimeout(function() {
          let top = projectElem.getBoundingClientRect().top;
          if (top < 0) {
            projectElem.scrollIntoView({ behavior: "smooth" });
          }
        }, 10);
      }
    });
  }

  function setProjectDateWidth() {
    let elems = document.querySelectorAll("#projectDate");
    let widths = [];
    elems.forEach((elem, i) => {
      let elemWidth = elem.offsetWidth + 1;
      widths.push(elemWidth);
    });

    let largestWidth = getMaxOfArray(widths);
    elems.forEach((elem, i) => {
      elem.style.width = `${largestWidth}px`;
    });
  }

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }

  function setProjectContentMargin() {
    let elem = document.querySelector("#projectName");
    let projectsDiv = document.querySelector(".projects");
    let paddingleft = window
      .getComputedStyle(projectsDiv, null)
      .getPropertyValue("padding-left");
    let margin = elem.getBoundingClientRect().left - parseInt(paddingleft, 10);
    setMargin(margin);

    // let divs = document.querySelectorAll("#porjectContent");
    // console.log(divs, margin);
    // divs.forEach((div, i) => {
    //   div.style.marginLeft = `${margin}px`;
    // });
  }

  return (
    <div className="projectsList">
      {projects.map((project, i) => {
        return (
          <Project
            key={i}
            keyProp={i}
            project={project}
            openProject={openProject}
            margin={margin}
          />
        );
      })}
    </div>
  );
}

export default Projects;
