import React, { useEffect } from "react";

function Approach(props) {
  useEffect(() => {
    if (props.approach.isOpen) {
      descriptionScroll();
    }
  });

  function descriptionScroll() {
    let descriptions = document.querySelector(".descriptions");

    if (descriptions) {
      let dWidth = descriptions.scrollWidth;
      let dScrollLeft = descriptions.scrollLeft;
      let windowWidth = window.innerWidth;
      console.log(dWidth, dScrollLeft, windowWidth);

      if (windowWidth > 768 && dScrollLeft < dWidth) {
        // descriptions.addEventListener(
        //   "mousewheel",
        //   function(e) {
        //     descriptions.scrollLeft -= e.wheelDelta;
        //     e.preventDefault();
        //   },
        //   false
        // );

        descriptions.onwheel = scrollInverse;
      }
    }
  }

  function scrollInverse(e) {
    let descriptions = document.querySelector(".descriptions");
    console.log(e);
    descriptions.scrollLeft -= e.wheelDelta;

    // window.scrollBy(0, -e.wheelDelta);
    // window.scrollBy(0, e.wheelDelta);
    // e.preventDefault();
  }

  function renderDescription() {
    let descriptions = [];
    props.approach.description.map((description, i) => {
      descriptions.push(
        <div key={i} className="descriptionBlock">
          <div className="numLine">
            <div className="num">
              <h4>{i + 1}</h4>
            </div>
            <div className="line"></div>
          </div>
          <h4 className="approachDescriptionText">{description}</h4>
        </div>
      );
    });
    return <div className="descriptions">{descriptions}</div>;
  }

  return (
    <div className="approachWraper">
      <div className="border" />
      <div
        className={props.approach.isOpen ? "approach open" : "approach"}
        keyattr={props.keyProp}
      >
        <div className="approachHeader">
          <h2>{props.approach.title}</h2>
          {props.approach.isOpen ? (
            ""
          ) : (
            <div
              className="approachButton"
              onClick={() => props.openApproach(props.approach.key)}
            >
              <h3>Подробнее</h3>
            </div>
          )}
        </div>
        {props.approach.isOpen ? (
          <>
            {props.approach.description ? renderDescription() : ""}
            <p className="approachCaption">{props.approach.caption}</p>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="border" />
    </div>
  );
}

export default Approach;
