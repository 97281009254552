import React, { useState, useEffect, useRef } from "react";
import Publication from "./publication.jsx";

function Project(props) {
  useEffect(() => {
    setTimeout(function() {
      resizeAllGridItems();
    }, 50);
    window.addEventListener("resize", resizeAllGridItems);
  });

  let margin = {
    marginLeft: props.margin
  };
  const publicationsRef = useRef(null);

  function resizeGridItem(item) {
    let grid = document.getElementsByClassName("publications")[0];
    let rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    );
    let rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    );
    let rowSpan = Math.ceil(
      item.querySelector(".content").getBoundingClientRect().height /
        (rowHeight + rowGap) +
        1
    );
    item.style.gridRowEnd = "span " + rowSpan;
  }

  function resizeAllGridItems() {
    let allItems = document.getElementsByClassName("publication");
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x]);
    }
  }

  return (
    <div
      keyattr={props.keyProp}
      className={props.project.isOpen ? "project open" : "project"}
    >
      <div className="projectHeader">
        <div className="dateName">
          <h2 id="projectDate">{props.project.date}</h2>
          <h2 id="projectName">{props.project.name}</h2>
        </div>
        {props.project.isOpen ? (
          ""
        ) : (
          <div
            className="projectButton"
            onClick={() => props.openProject(props.project.key)}
          >
            <h3>Подробнее</h3>
          </div>
        )}
      </div>

      {props.project.isOpen ? (
        <div id="projectContent" style={margin}>
          <p
            dangerouslySetInnerHTML={{ __html: props.project.description.html }}
            className="projectDescription"
          ></p>
          <h4>Публикации</h4>
          <div className="publications" ref={publicationsRef}>
            {props.project.publication.map((publication, i) => {
              return <Publication key={i} publication={publication} />;
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Project;
