import React from "react";

function Publication(props) {
  let style = {};
  if (props.rect) {
    style = {
      zIndex: 1000,
      position: "absolute",
      top: props.rect.y + props.rect.height,
      left: props.rect.x + props.rect.width / 4,
      display: "block",
      lineHeight: "0rem",
      textAlign: "left",
      borderBottom: "none"
    };
  }
  return (
    <a
      className="publication"
      style={style}
      href={props.publication.link}
      target="_blank"
    >
      <div className="content">
        {props.publication.image ? (
          <img src={props.publication.image.url} />
        ) : (
          ""
        )}
        <p className="caption">{props.publication.title}</p>
        <p className="cardText">{props.publication.text}</p>
      </div>
    </a>
  );
}

export default Publication;
