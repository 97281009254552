import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import * as serviceWorker from "./serviceWorker";
import QUERY from "./fetchData.js";

const client = new ApolloClient({
  uri:
    "https://api-eu-central-1.graphcms.com/v2/ck8z41yhf2lt501xp7unl5jha/master"
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Query query={QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <div>Fetching info.....</div>;
        if (error) return <div>Error fetching info</div>;

        return (
          <div>
            <App {...data} />
          </div>
        );
      }}
    </Query>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
