import React, { useState, useEffect } from "react";
import Time from "./time.jsx";

function Header(props) {
  let link = `mailto:${props.email}`;
  return (
    <div className="header">
      <Time />
      <h3 className="email">
        <a href={link}>{props.email}</a>
      </h3>
    </div>
  );
}

export default Header;
