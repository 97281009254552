import React, { useState, useEffect } from "react";

function Time() {
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [isColon, setIsColon] = useState(true);

  useEffect(() => {
    calcTime();

    const interval = setInterval(() => {
      calcTime();
      setIsColon(!isColon);
    }, 500);

    return () => clearInterval(interval);
  });

  function calcTime() {
    let time = dateToGMT();
    setHours(time.hours);
    setMins(time.minutes);
  }

  function dateToGMT(date = new Date(), offset = +3) {
    let hours = date.getUTCHours() + offset;
    if (hours > 23) hours = hours - 24;
    if (hours < 0) hours = 24 + hours;
    let minutes = date.getUTCMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }

    let time = {
      hours: hours,
      minutes: minutes
    };
    return time;
  }

  return (
    <h3 className="time">
      Москва, {hours}
      <span>{isColon ? ":" : " "}</span>
      {mins}
    </h3>
  );
}

export default Time;
