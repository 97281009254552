import React, { useState, useEffect } from "react";

function Gallery(props) {
  const [caption, setCaption] = useState(props.gallery.image[0].caption);
  const [number, setNumber] = useState(0);

  function changeImage(i) {
    if (i >= 0 && i <= props.gallery.image.length - 1) {
      setCaption(props.gallery.image[i].caption);
      setNumber(i);
    }
  }

  return (
    <div className="gallery">
      <div className="images">
        <div className="galleryImage">
          <img src={props.gallery.image[number].image.url} />
          <div className="clickers">
            <div className="left" onClick={() => changeImage(number - 1)} />
            <div className="right" onClick={() => changeImage(number + 1)} />
          </div>
        </div>
      </div>
      <p>
        <span className="caption">{caption}</span>
        <span className="caption">
          {number + 1}/{props.gallery.image.length}
        </span>
      </p>
    </div>
  );
}

export default Gallery;
