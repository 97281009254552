import React from "react";
import "./stylesheets/main.scss";
import Header from "./components/header.jsx";
import MainText from "./components/mainText.jsx";
import Projects from "./components/projects.jsx";
import Approaches from "./components/approaches.jsx";
import Gallery from "./components/gallery.jsx";

function App(props) {
  let email_link = `mailto:${props.general.email}`;
  let telegram_link = `https://t.me/${props.general.telegram}`;
  let journal_link = `https://journal.prhub.moscow`;
  let telegram_journal_link = `https://t.me/prhubmoscow`;
  return (
    <>
      <Header email={props.general.email} />
      <div className="logo"></div>

      <section className="firstScreen">
        <MainText
          text={props.general.mainText.html}
          publications={props.publications}
        />
        <Gallery gallery={props.general.gallery} />
        <div id="links"></div>
      </section>

      <section className="projects">
        <h1>Очень хотим поделиться</h1>
        <Projects projects={props.projects} />
      </section>

      <section className="approachSection">
        <h1>Наш подход</h1>
        <div
          className="approachText"
          dangerouslySetInnerHTML={{ __html: props.general.approach.html }}
        ></div>
        <Approaches approaches={props.approaches} />
      </section>

        <section className="partners">
            <div
                className="partnersText"
                dangerouslySetInnerHTML={{__html: props.general.partners.html}}
            ></div>
            <div className="logos">
                {props.partners.map((partner, i) => {
                    let style = {
                        maxWidth: partner.logoWidth,
                        backgroundImage: `url(${partner.logo.url})`
                    };
                    return <div key={i} style={style} className="img"/>;
                })}
            </div>
            <div>
                <a className="emailButton" href={email_link} target="_blank">
                    <h3>{props.general.email}</h3>
                </a></div>
            <div className={'link-wrapper'}><a className="telegramButton" href={telegram_link} target="_blank">
                <h3>telegram: @{props.general.telegram}</h3>
            </a></div>
            <div
                className="partnersText"
                style={{marginTop: 30}}
            ><h1>Подписывайтесь на Журнал Пиархаба:</h1></div>
            <div className={'link-wrapper'}><a className={'tg-logo-link'} href={telegram_journal_link} target="_blank">
                <div className="tgLogo"></div>
            </a></div>
        </section>

        <div className="footer">
            <p>© ПИАРХАБ {new Date().getFullYear()}</p>
            <p>Москва, Деловой квартал "Рассвет", Столярный пер. 3к8, 1 этаж <br />+7 (996) 990-69-74</p>
      </div>
    </>
  );
}

export default App;
